<template>
  <div class="tibox">
    <div class="timain">
      <div class="tihead">
        <div class="tinav">
          <!-- <el-date-picker
            :style="arr"
            v-model="value"
            type="daterange"
            value-format="YYYY-MM-DD"
            range-separator="至"
            start-placeholder="起始时间"
            end-placeholder="结束时间"
            @change="change"
          >
          </el-date-picker> -->

          <!-- 创建时间 -->
          <el-form ref="form">
            <el-form-item label="创建时间">
              <el-col :span="11">
                <el-date-picker
                  v-model="value1"
                  value-format="YYYY-MM-DD"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </el-col>
            </el-form-item>
          </el-form>

          <!-- 完成时间 -->
          <el-form ref="form">
            <el-form-item label="完成时间">
              <el-col :span="11">
                <el-date-picker
                  v-model="value2"
                  value-format="YYYY-MM-DD"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </el-col>
            </el-form-item>
          </el-form>
          <!-- 救援时间 -->
          <el-form ref="form">
            <el-form-item label="救援时间">
              <el-col :span="11">
                <el-date-picker
                  v-model="value3"
                  value-format="YYYY-MM-DD"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="起始时间"
                  end-placeholder="结束时间"
                >
                </el-date-picker>
              </el-col>
            </el-form-item>
          </el-form>

          <!-- 搜索 -->
          <div class="left">
            <el-select
              filterable
              v-model="keyword"
              placeholder="全部技师"
              @change="search"
            >
              <el-option
                v-for="item1 in techlist"
                :key="item1.uid"
                :value="item1.uid"
                :label="item1.nickname"
              >
              </el-option>
            </el-select>
          </div>

          <div class="seek">
            <input
              class="sekint"
              type="text"
              v-model="keytit"
              placeholder="输入车牌号"
            />
            <button class="chaxun" @click="search">查询</button>
          </div>
        </div>
        <!-- 按钮 -->
        <div class="btn">
          <button class="cancel" @click="daochu" v-if="canExport && !getlook">导出</button>
        </div>
      </div>

      <div class="tibiao">
        <!--<div style="display:flex">
        <<span v-if="(getisFin || etcEdit)  && !getlook" class="etcedit">当前您已处理ETC总数量/当月：{{t}}/{{t_m}} 个 </span><span  v-if="(getisFin || etcEdit)  && !getlook" class="etcedit">   已处理总费用/当月：{{m}}/{{m_m}} 元</span></div>-->
        <el-table :data="tilist" stripe style="margin-top:20px;">
          <el-table-column label="编号" width="190">
            <template #default="scope">
              <span class="xiangqing" @click="jion(scope.row.rescueId)">{{ scope.row.rescueId }}</span>
            </template>
          </el-table-column>
          <el-table-column label="合作单位/服务项目" width="250">
            <template #default="scope">
              {{ scope.row.partner }}
              <br />
              {{ scope.row.rescueType }}
            </template>
          </el-table-column>
          <el-table-column label="技师信息" width="200">
            <template #default="scope">
              {{ scope.row.tech_name }}
              <br />
              {{ scope.row.tech_mobile
              }}<button
                @click="call(scope.row.tech_mobile, scope.row.rescueId)"
                class="call"
                v-show="getEmic != ''"
              ></button>
            </template>
          </el-table-column>
          <el-table-column label="车辆信息" width="200">
            <template #default="scope">
              {{ scope.row.tech_car_number }}/{{ scope.row.tech_car_type }}
            </template>
          </el-table-column>

          <el-table-column label="收费信息" width="130">
            <template #default="scope">
              {{ scope.row.price }}元/{{ scope.row.pricetype }}
            </template>
          </el-table-column>
          <el-table-column label="ETC" width="130">
            <template #default="scope">
              <div v-if="(getisFin || etcEdit) && !getlook">
                <input
                  v-model="scope.row.bridge"
                  style="width: 40px; margin-right: 5px; text-align: center"
                  onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                />元
                <el-button
                  link
                  type="primary"
                  size="small"
                  @click="changeetc(scope.row.rescueId, scope.row.bridge)"
                  >修改</el-button
                >
              </div>
              <div v-if="!(getisFin || etcEdit) || getlook">{{ scope.row.bridge }}元</div>
            </template>
          </el-table-column>
          <el-table-column label="提成" width="150">
            <template #default="scope">
               <div v-if="(getisFin || etcEdit) && scope.row.price == 0 && !getlook">
                <input
                  v-model="scope.row.tc_money"
                  style="width: 40px; margin-right: 5px; text-align: center"
                  onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                />元/{{
                scope.row.tc_percent * 100
              }}%
                <el-button
                  link
                  type="primary"
                  size="small"
                  @click="changeCom(scope.row.rescueId, scope.row.tc_money)"
                  >修改</el-button
                >
              </div>
              <div v-if="!(getisFin || etcEdit) || scope.row.price > 0 || getlook">{{ parseFloat(scope.row.tc_money) }}元/{{
                scope.row.tc_percent * 100
              }}%</div>

            </template>
          </el-table-column>
          <el-table-column label="创建/接入时间" width="200">
            <template #default="scope">
              {{ scope.row.createTime }}
            </template>
          </el-table-column>
          <el-table-column label="完成时间" width="200">
            <template #default="scope">
              {{ scope.row.finish_time }}
            </template>
          </el-table-column>
          <!-- <el-table-column fixed="right" label="操作">
            <template #default="scope">
              <el-button
                link
                type="primary"
                size="small"
                @click="jion(scope.row.rescueId)"
                >查看</el-button
              >
            </template>
          </el-table-column> -->
        </el-table>




        <!-- 分页器 -->
        <div class="jinXing-sorter">
          <div style="float:left;margin-left:20px;">
            <div class="etcprice" v-if="tech!= ''">{{tech}}</div>
            <div class="etcprice" v-if="chanliang != ''">订单：{{ chanliang }}个</div>
            <div class="etcprice"  v-if="chanzhi != ''">产值：{{ chanzhi }}元</div>
            <div class="etcprice" v-if="yeji != ''">业绩：{{ yeji }}元</div>
            <div class="etcprice">ETC：{{ etcprice }}元</div>
          </div>

          <div style="float:right;">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="total"
            :page-size="datas.perpage"
            @prev-click="prev_view"
            @next-click="next_view"
            @size-change="size_view"
            @current-change="current_view"
          /></div>
        </div>
      </div>
    </div>
  </div>

  <part :islook="clook" :id="crescueId"></part>
</template>

<script>
import { ref } from "vue";
import * as api2 from "../api/index.js";
import * as api from "../api/join.js";
import * as api1 from "../api/order";
import * as ola from "../api/olawebsock.js";
import Part from "../components/part.vue";
import { ElMessageBox } from "element-plus";
const value = ref("");
export default {
  components: { Part },
  data() {
    return {
      value,
      value1:'',
      value2:'',
      value3:'',
      keyword: "",
      total: "",
      tilist: [],
      tilist1: [],
      techlist: [],
      datas: {
        page: 1,
        perpage: 7,
        keyword: "",
        createTimeBegin: "",
        createTimeEnd: "",
        finishTimeBegin: "",
        finishTimeEnd: "",
        rescueTimeBegin:"",
        rescueTimeEnd:"",
        carnumber: "",
      },
      clook: "",
      crescueId: "",
      emicreg: "",
      etcprice: "",

      keytype: "",
      keytit: "",
      chanliang:'',
      chanzhi:'',
      yeji:'',
      tech:'',
      canExport:false,
      etcEdit:false,
      t:0,
      t_m:0,
      m:0,
      m_m:0,
    };
  },
  async created() {
    this.res_getCommission(this.datas);
    this.gettechlist();
  },

  methods: {
    change(e) {
      console.log("点击选中-----", e);
    },

    search() {
      console.log("点击搜索", this.keyword, this.value);
      let a1 = this.value1 !='' && this.value1 != null ?this.value1[0]:'';
      let a2 = this.value1 !='' && this.value1 != null ?this.value1[1]:'';
      let b1 = this.value2 !='' && this.value2 != null ?this.value2[0]:'';
      let b2 = this.value2 !='' && this.value2 != null ?this.value2[1]:'';
      let c1 = this.value3 !='' && this.value3 != null ?this.value3[0]:'';
      let c2 = this.value3 !='' && this.value3 != null ?this.value3[1]:'';
      this.datas.keyword = this.keyword;
      this.datas.createTimeBegin = a1;
      this.datas.createTimeEnd = a2;
      this.datas.finishTimeBegin = b1;
      this.datas.finishTimeEnd = b2;
      this.datas.rescueTimeBegin = c1;
      this.datas.rescueTimeEnd = c2;
      this.datas.carnumber = this.keytit;
      console.log(this.datas);
      this.res_getCommission(this.datas);
    },
    changeetc(id, money) {
      console.log(id);
      console.log(money);
      ElMessageBox.confirm("是否确定修改ETC费用", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let xiures = await api.xiugaietc(id, money);
          console.log("修改数据---", xiures);
          if(xiures.code == 1)
          {
            this.$message.success("修改成功");
          }else{
            this.$message.warning(xiures.message);
          }
          
          this.res_getCommission(this.datas);
        })
        .catch(() => {
          this.$message.info("取消修改");
        });
    },
     changeCom(id, money) {
      console.log(id);
      console.log(money);
      ElMessageBox.confirm("是否确定修改提成吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let xiures = await api.xiugaiCom(id, money);
          console.log("修改数据---", xiures);
          if(xiures.code == 1)
          {
            this.$message.success("修改成功");
          }else{
            this.$message.warning(xiures.message);
          }
          
          this.res_getCommission(this.datas);
        })
        .catch(() => {
          this.$message.info("取消修改");
        });
    },
    async res_getCommission(data) {
      let res_getCommission = await api.getCommission(data);
      console.log("提成列表：", res_getCommission);
      this.tilist = res_getCommission.data;
      this.tilist1 = res_getCommission.data;
      this.etcprice = res_getCommission.totaletc;
      this.chanzhi = res_getCommission.chanzhi;
      this.chanliang = res_getCommission.chanliang;
      this.yeji = res_getCommission.yeji;
      this.tech = res_getCommission.tech;
      this.t = res_getCommission.t;
      this.t_m = res_getCommission.t_m;
      this.m = res_getCommission.m;
      this.m_m = res_getCommission.m_m;

      this.total = parseInt(res_getCommission.total);
      this.etcEdit = res_getCommission.etcEdit;
    },
    async gettechlist() {
      let res = await api2.gettechlist(1);
      this.techlist = res.data;
      this.canExport = res.tichengExport;
    },
    jion(id) {
      this.crescueId = id;
      this.clook++;
      if (this.clook > 2) {
        this.clook = 1;
      }
    },
    async keyword1() {
      this.datas.carnumber = this.keytit;
      this.res_getCommission(this.datas);
    },
    // 分页器
    prev_view() {
      console.log("---合作上一页", this.datas.page);
    },
    // 分页器
    next_view() {
      console.log("---合作下一页", this.datas.page);
    },
    // 点击页数时触发
    current_view(e) {
      this.datas.page = e;
      this.res_getCommission(this.datas);
    },
    daochu() {
      // let res = await api1.commissionlistExport(this.datas);
      let a1 = this.value1 !='' && this.value1 != null ?this.value1[0]:'';
      let a2 = this.value1 !='' && this.value1 != null ?this.value1[1]:'';
      let b1 = this.value2 !='' && this.value2 != null ?this.value2[0]:'';
      let b2 = this.value2 !='' && this.value2 != null ?this.value2[1]:'';
      let c1 = this.value3 !='' && this.value3 != null ?this.value3[0]:'';
      let c2 = this.value3 !='' && this.value3 != null ?this.value3[1]:'';
      this.datas.keyword = this.keyword;
      this.datas.createTimeBegin = a1;
      this.datas.createTimeEnd = a2;
      this.datas.finishTimeBegin = b1;
      this.datas.finishTimeEnd = b2;
      this.datas.rescueTimeBegin = c1;
      this.datas.rescueTimeEnd = c2;
      this.datas.carnumber = this.keytit;

      let ct = -1;
      let at = -1;
      let rt = -1;

      if(Object.keys(this.datas).length == 0 || (this.datas.createTimeBegin == '' && this.datas.createTimeEnd == '' && this.datas.finishTimeBegin == '' && this.datas.finishTimeEnd == '' && this.datas.rescueTimeBegin == '' && this.datas.rescueTimeEnd == ''))
      {
          this.$message.warning("请选择具体的导出日期");
          return false;
      }

      if(this.datas.createTimeBegin != '' && this.datas.createTimeEnd != '')
      {
        ct = (Date.parse(this.datas.createTimeEnd) - Date.parse(this.datas.createTimeBegin))/86400000;
      }

      if(this.datas.finishTimeBegin != '' && this.datas.finishTimeEnd != '')
      {
        at = (Date.parse(this.datas.finishTimeEnd) - Date.parse(this.datas.finishTimeBegin))/86400000;
      }


      if(this.datas.rescueTimeBegin != '' && this.datas.rescueTimeEnd != '')
      {
        rt = (Date.parse(this.datas.rescueTimeEnd) - Date.parse(this.datas.rescueTimeBegin))/86400000;
      }

      if(ct > 31)
      {
          this.$message.warning("创建时间导出不能超出1个月，请重新选择");
          return false;
      }

      if(at > 31)
      {
          this.$message.warning("完成时间导出不能超出1个月，请重新选择");
          return false;
      }

      if(rt > 31)
      {
          this.$message.warning("救援时间导出不能超出1个月，请重新选择");
          return false;
      }

      //return false;

      this.$msgbox({
        title: "消息",
        message: "是否确定导出",
        showCancelButton: true,
        closeOnClickModal: false,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "导出中...";
            setTimeout(async () => {
              let res = await api1.commissionlistExport(this.datas);
              console.log("打印结果", res);
              done();
              if (res.status == 200) {
                instance.confirmButtonLoading = false;
              }
            });
          } else {
            done();
          }
        },
      }).then( () => {
        this.$message.success("导出成功请保存");
      }).catch( () => {
        this.$message.warning('取消导出')
      });


    },
    call(tel, str) {
      let ue =  this.$store.state.emic;
      let mode = this.$store.state.emicmode;

      console.log("拨打电话", tel);
      ue.call.callout({
        customerNumber:tel.toString(),
        loginType:mode,
        extras:str
      });
    },
  },
  computed: {
    getEmic() {
      return this.$store.state.emic;
    },
    getisFin() {
      return this.$store.state.isFin;
    },
    getlook()
    {
      return this.$store.state.lookonly;
    }
  },
  watch: {},
};
</script>

<style lang="less" scoped>
.tibox {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
.timain {
  width: 1730px;
  height: 100%;
  margin-left: 190px;
  padding: 30px;
  box-sizing: border-box;
  background-color: #f8fafc;
}
.tinav {
  display: flex;
  align-items: center;
}
.tihead {
  width: 100%;
  height: 100px;
  background-color: #fff;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}
.left {
  float: left;
  // width: 300px;
  height: 32px;
  background: #ffffff;
  // border: 1px solid #dadfe6;
  opacity: 1;
  border-radius: 2px;
  position: relative;
  box-sizing: border-box;
  margin-left: 20px;
}
.jing {
  float: left;
  width: 32px;
  height: 32px;
  background: #f7f9fb;
  border: 1px solid #dadfe6;
  opacity: 1;
  border-radius: 2px;
  position: absolute;
  top: -1px;
  right: -1px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}
.icon {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  opacity: 1;
}
.tisou,
.tiru {
  width: 100%;
  height: 100%;
  border: 0;
  box-sizing: border-box;
  padding-left: 12px;
  outline: none;
}
.tiru {
  border: 1px solid #ccc;
}
.tishu {
  width: 250px;
  height: 32px;
  box-sizing: border-box;
  margin-left: 20px;
}
.btn {
  float: right;
  // width: 180px;
  height: 32px;
  opacity: 1;
  box-sizing: border-box;
}
.set {
  width: 80px;
  height: 32px;
  background: #2c68ff;
  opacity: 1;
  color: #fff;
  border-radius: 2px;
  border: 0;
  cursor: pointer;
  box-sizing: border-box;
}
.cancel {
  width: 80px;
  height: 32px;
  background: #ffffff;
  margin-left: 20px;
  border: 1px solid #727e96;
  opacity: 1;
  border-radius: 2px;
  cursor: pointer;
  box-sizing: border-box;
}
.tibiao {
  width: 100%;
  height: 665px;
  background-color: #fff;
  padding: 0 15px;
  box-sizing: border-box;
  //   margin-top: 20px;
}
.tibiao /deep/ .el-table th.el-table__cell.is-leaf {
  background-color: #f5f5f5;
  height: 50px;
  color: #000;
}
.tibiao /deep/ .el-table__row {
  height: 70px !important;
  box-sizing: border-box;
}

.tibiao /deep/ .el-table__cell {
  padding: 0;
}
.jinXing-sorter {
  display: inline;
  justify-content: flex-end;
  position: absolute;
  right: 40px;
  bottom: 50px;
  width:1640px;
}
.call {
  display: inline-block;
  width: 25px;
  height: 25px;
  background: url("../assets/img/index/call.png");
  background-size: contain;
  border: none;
  cursor: pointer;
  box-sizing: border-box;
  vertical-align: middle;
  margin-left: 5px;
}
.carcall {
  display: flex;
  align-items: center;
}
.left /deep/ .el-select .el-input {
  width: 200px;
  margin-right: 20px;
}
.etcprice {
  display: flex;
  align-items: center;
  font-size: 20px;
  margin-right: 30px;
  color: #606266;
  float:left;
}

.etcedit {
  display: flex;
  align-items: center;
  font-size: 20px;
  margin-left: 30px;
  color: #606266;
}


.seek {
  float: left;
  // width: 200px;
  // height: 40px;
  background: #ffffff;
  // border: 1px solid #dadfe6;
  opacity: 1;
  box-sizing: border-box;
  border-radius: 2px;
  position: relative;
  display: flex;
}
.sekint {
  float: left;
  width: 200px;
  height: 37px;
  padding: 15px;
  outline: none;
  border: none;
  box-sizing: border-box;
  height: 32px;
  border: 1px solid #dadfe6;
}
.glass {
  // float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 30px;
  margin-top: -1px;
  background: #f7f9fb;
  // border: 1px solid #dadfe6;
  opacity: 1;
  border-radius: 2px;
  position: absolute;
  top: 2px;
  right: 1px;
  cursor: pointer;
}
.icon {
  width: 14px;
  height: 14px;
  // margin-left: 13px;
  // margin-top: 13px;
  cursor: pointer;
}

.chaxun {
  width: 80px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2c68ff;
  color: #fff;
  border: 0;
  cursor: pointer;
}
.tinav /deep/ .el-range-editor.el-input__inner {
  width: 250px;
  margin: 0 !important;
}
.tinav /deep/ .el-form-item {
  margin: 0 !important;
  margin-right: 20px !important;
}
.xiangqing {
  font-style: normal;
  color: #2c68ff;
  cursor: pointer;
}
</style>